


















































import { Component, Vue } from 'vue-property-decorator';
import { readTiposLocalidade } from '@/store/tipo_localidade/getters';
import { dispatchDeleteTipoLocalidade, dispatchGetTiposLocalidade } from '@/store/tipo_localidade/actions';
import { ITipoLocalidade } from '@/interfaces/tipo_localidade';
import ModalConfirmacao from '@/components/ModalConfirmacao.vue';

@Component({
  components: {
    ModalConfirmacao,
  },
})
export default class ListarTiposLocalidade extends Vue {
  public modalExclusaoAtivo: boolean = false;
  public tipoLocalidadeExclusao;

  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'center',
    },
    {
      text: 'Descrição',
      sortable: true,
      value: 'descricao',
      align: 'left',
    },
    {
      text: 'Sigla',
      sortable: true,
      value: 'sigla',
      align: 'left',
    },
    {
      text: 'Ações',
      align: 'center',
    },
  ];

  public options = {
    rowsPerPage: 10,
  };

  get tiposLocalidade() {
    return readTiposLocalidade(this.$store);
  }

  public abrirModalExclusao(tipoLocalidade: ITipoLocalidade) {
    this.tipoLocalidadeExclusao = tipoLocalidade;
    this.modalExclusaoAtivo = true;
  }

  public async confirmarExclusao() {
    const excluiu = await dispatchDeleteTipoLocalidade(this.$store, this.tipoLocalidadeExclusao);
    dispatchGetTiposLocalidade(this.$store);
    this.modalExclusaoAtivo = false;
  }

  public async mounted() {
    await dispatchGetTiposLocalidade(this.$store);
  }
}
